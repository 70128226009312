export const ROUTE_BLOCKED_ACCESS = 'ROUTE_BLOCKED_ACCESS'

export const ROUTE_DASHBOARD_AGENTS = 'ROUTE_DASHBOARD_AGENTS'
export const ROUTE_DASHBOARD_QUEUES = 'ROUTE_DASHBOARD_QUEUES'
export const ROUTE_DASHBOARD_ATTENDANCE = 'ROUTE_DASHBOARD_ATTENDANCE'

export const ROUTE_DASHBOARD_MAILING = 'ROUTE_DASHBOARD_MAILING'
export const ROUTE_DASHBOARD_MAILING_VIEW = 'ROUTE_DASHBOARD_MAILING_VIEW'
export const ROUTE_DASHBOARD_MAILING_IMPORT = 'ROUTE_DASHBOARD_MAILING_IMPORT'
export const ROUTE_DASHBOARD_MAILING_CONTACTS = 'ROUTE_DASHBOARD_MAILING_CONTACTS'

export const ACTION_AGENT_PAUSE = 'ACTION_AGENT_PAUSE'
export const ACTION_AGENT_LOGOUT = 'ACTION_AGENT_LOGOUT'
export const ACTION_AGENT_PENALTY = 'ACTION_AGENT_PENALTY'
export const ACTION_AGENT_UNPAUSE = 'ACTION_AGENT_UNPAUSE'
