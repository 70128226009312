const isEqual = (source, target, deep = true) => {
  if (source === target) return true
  if (source === null || target === null) return false

  const sourceType = typeof source
  const targetType = typeof target
  if (sourceType !== targetType) return false
  if (sourceType !== 'object') return false

  const sourceKeys = Object.keys(source)
  const targetKeys = Object.keys(target)
  if (sourceKeys.length !== targetKeys.length) return false
  if (deep) return !sourceKeys.some((key) => !isEqual(source[key], target[key], false))
  return JSON.stringify(source) === JSON.stringify(target)
}

export default isEqual
