import EventEmitter from 'mittens'
import pkg from '@/../package.json'
import axios from 'axios'

class VonixJS {
  constructor (customerId, accessToken) {
    const vonixJS = this
    vonixJS.customerId = customerId || 'blackbox'
    vonixJS.accessToken = accessToken

    vonixJS.heartPing = null
    vonixJS.heartWait = null

    vonixJS.events = new EventEmitter()
    vonixJS.connected = false
    vonixJS.connection = null
    vonixJS.waitConnect = null

    vonixJS.onOpen = (args) => {
      vonixJS.connected = true
      vonixJS.events.emit('CONNECTED', args)
    }

    vonixJS.onClose = (err) => {
      vonixJS.connected = false
      vonixJS.connection?.removeEventListener('open', vonixJS.onOpen)
      vonixJS.connection?.removeEventListener('close', vonixJS.onClose)
      vonixJS.connection?.removeEventListener('message', vonixJS.onMessage)
      vonixJS.connection = null
      vonixJS.waitConnect = null
      vonixJS.events.emit('DISCONNECTED', err)
    }

    vonixJS.onMessage = (msg) => {
      vonixJS.emitEvent(msg)
    }

    window.execEvent = vonixJS.execEvent.bind(vonixJS)
  }

  sendHeartPing () {
    const vonixJS = this
    if (!vonixJS.connection) return

    const heartPing = Date.now()
    vonixJS.heartPing = heartPing
    vonixJS.heartWait = setTimeout(() => vonixJS.setPingNotResponse(), 7000)
    vonixJS.connection.send(JSON.stringify({ heartPing }))
  }

  setPingNotResponse () {
    const vonixJS = this
    vonixJS.heartPing = null
    clearTimeout(vonixJS.heartWait)
    vonixJS?.connection?.close()
  }

  restartHeartPing () {
    const vonixJS = this
    vonixJS.heartPing = null
    vonixJS.heartWait && clearTimeout(vonixJS.heartWait)
    setTimeout(() => vonixJS.sendHeartPing(), 30000)
  }

  close () {
    const vonixJS = this
    vonixJS.events.off()

    try {
      vonixJS.connection?.close()
    } catch (error) {
      console.log(error)
    }

    vonixJS.connected = false
    vonixJS.connection = null
    vonixJS.waitConnect = null
  }

  async getToken () {
    const vonixJS = this
    const isFunc = typeof vonixJS.accessToken === 'function'
    return isFunc ? vonixJS.accessToken() : vonixJS.accessToken
  }

  emitEvent (msg) {
    const vm = this
    if (!msg?.data) return

    let eventData = null

    try {
      eventData = JSON.parse(msg.data)
    } catch {
      return
    }

    if (!Array.isArray(eventData)) eventData = [eventData]

    for (const eventRow of eventData) {
      vm.execEvent(eventRow)
    }
  }

  execEvent (evt) {
    const vm = this

    const isHeartPong = evt?.heartPong === vm.heartPing
    if (isHeartPong) return vm.restartHeartPing()

    const eventName = evt?.event?.event?.toUpperCase()?.trim()
    if (eventName) return vm.events.emit(eventName, evt)
  }

  registerListener (event, onEvent) {
    this.events.on(event, onEvent)
    return () => this.events.off(event, onEvent)
  }

  getConnection () {
    const vm = this

    if (vm.connected) return Promise.resolve(vm.connection)
    if (vm.waitConnect) return this.waitConnect

    vm.waitConnect = new Promise((resolve, reject) => {
      const unregisterEventConnect = vm.onConnected(() => {
        unregisterEventConnect()
        unregisterEventDisconnect()
        vm.restartHeartPing()
        resolve(vm.connection)
      })

      const unregisterEventDisconnect = vm.onDisconnected((err) => {
        unregisterEventConnect()
        unregisterEventDisconnect()
        reject(err)
      })

      vm.getHost().then((hostEvents) => {
        if (!hostEvents) return vm.events.emit('DISCONNECTED', new Error('invalid token'))
        vm.connection = new WebSocket(hostEvents)
        vm.connection.addEventListener('open', vm.onOpen)
        vm.connection.addEventListener('close', vm.onClose)
        vm.connection.addEventListener('message', vm.onMessage)
      })
    })

    return vm.waitConnect
  }

  sendCommand (command) {
    return this.getConnection().then((connection) => connection.send(JSON.stringify(command)))
  }

  setInterests (queues, agents, extensions) {
    return this.sendCommand({
      act: 'setInterests',
      queues: toArrayStr(queues),
      agents: toArrayStr(agents),
      extensions: toArrayStr(extensions)
    })
  }

  onInitialState (eventCallback) {
    return this.registerListener(
      events.onInitialState,
      eventCallback
    )
  }

  onDisconnected (eventCallback) {
    return this.registerListener(
      events.onDisconnected,
      eventCallback
    )
  }

  onConnected (eventCallback) {
    return this.registerListener(
      events.onConnected,
      eventCallback
    )
  }

  onChangeset (eventCallback) {
    return this.registerListener(
      events.onChangeset,
      eventCallback
    )
  }

  onAgentLogin (eventCallback) {
    return this.registerListener(
      events.onAgentLogin,
      eventCallback
    )
  }

  onAgentLogoff (eventCallback) {
    return this.registerListener(
      events.onAgentLogoff,
      eventCallback
    )
  }

  onAgentPause (eventCallback) {
    return this.registerListener(
      events.onAgentPause,
      eventCallback
    )
  }

  onAgentUnpause (eventCallback) {
    return this.registerListener(
      events.onAgentUnpause,
      eventCallback
    )
  }

  onAgentPenalty (eventCallback) {
    return this.registerListener(
      events.onAgentPenalty,
      eventCallback
    )
  }

  onDialerStart (eventCallback) {
    return this.registerListener(
      events.onDialerStart,
      eventCallback
    )
  }

  onDialerConnect (eventCallback) {
    return this.registerListener(
      events.onDialerConnect,
      eventCallback
    )
  }

  onDialerEnd (eventCallback) {
    return this.registerListener(
      events.onDialerEnd,
      eventCallback
    )
  }

  onDialerStatus (eventCallback) {
    return this.registerListener(
      events.onDialerStatus,
      eventCallback
    )
  }

  onCallRouteChange (eventCallback) {
    return this.registerListener(
      events.onCallRouteChange,
      eventCallback
    )
  }

  onCallConnect (eventCallback) {
    return this.registerListener(
      events.onCallConnect,
      eventCallback
    )
  }

  onCallAttempt (eventCallback) {
    return this.registerListener(
      events.onCallAttempt,
      eventCallback
    )
  }

  onCallReject (eventCallback) {
    return this.registerListener(
      events.onCallReject,
      eventCallback
    )
  }

  onCallDump (eventCallback) {
    return this.registerListener(
      events.onCallDump,
      eventCallback
    )
  }

  onCallStart (eventCallback) {
    return this.registerListener(
      events.onCallStart,
      eventCallback
    )
  }

  onCallEnd (eventCallback) {
    return this.registerListener(
      events.onCallEnd,
      eventCallback
    )
  }

  onIvrHangup (eventCallback) {
    return this.registerListener(
      events.onIvrHangup,
      eventCallback
    )
  }

  onQueueJoin (eventCallback) {
    return this.registerListener(
      events.onQueueJoin,
      eventCallback
    )
  }

  onQueueAbandon (eventCallback) {
    return this.registerListener(
      events.onQueueAbandon,
      eventCallback
    )
  }

  onQueueReload (eventCallback) {
    return this.registerListener(
      events.onQueueReload,
      eventCallback
    )
  }

  onAgentChatConnect (eventCallback) {
    return this.registerListener(
      events.onAgentChatConnect,
      eventCallback
    )
  }

  onAgentChatDisconnect (eventCallback) {
    return this.registerListener(
      events.onAgentChatDisconnect,
      eventCallback
    )
  }

  onFinish (eventCallback) {
    return this.registerListener(
      events.onFinish,
      eventCallback
    )
  }

  onAccept (eventCallback) {
    return this.registerListener(
      events.onAccept,
      eventCallback
    )
  }

  extensionsUpdate (eventCallback) {
    return this.registerListener(
      events.extensionsUpdate,
      eventCallback
    )
  }

  onTransfer (eventCallback) {
    return this.registerListener(
      events.onTransfer,
      eventCallback
    )
  }

  onReject (eventCallback) {
    return this.registerListener(
      events.onReject,
      eventCallback
    )
  }

  onPendingChat (eventCallback) {
    return this.registerListener(
      events.onPendingChat,
      eventCallback
    )
  }

  onOffer (eventCallback) {
    return this.registerListener(
      events.onOffer,
      eventCallback
    )
  }

  onRingCancel (eventCallback) {
    return this.registerListener(
      events.onRingCancel,
      eventCallback
    )
  }

  async getHost () {
    let host = null
    const vm = this

    let stage = pkg.stage || 'vonix'
    if (stage === 'alpha') stage = 'beta'
    if (stage === 'stable') stage = 'production'

    while (!host) {
      const token = await vm.getToken()
      const isEmpty = ['null', 'undefined', '', '0', 'false', 'f', 'n'].includes(String(token || '').trim().toLowerCase())
      if (isEmpty) return null

      const result = await axios.get(`https://${vm.customerId}.notifier.vonixcc.com.br/${stage}/info?token=${token}`).catch(e => console.error(e))
      host = result?.data?.wss_url
    }

    return host
  }
}

export default VonixJS

const toStr = (id) => id ? String(id).trim() : null
const toArrayStr = (val) => Array.isArray(val) ? val.map(toStr).filter(v => v) : []

const events = {
  onInitialState: 'INITIAL_STATE',
  onDisconnected: 'DISCONNECTED',
  onConnected: 'CONNECTED',
  onChangeset: 'CHANGESET',
  onRingCancel: 'QUEUEMEMBER_RINGCANCELED',
  onAgentLogin: 'QUEUEMEMBER_LOGIN',
  onAgentLogoff: 'QUEUEMEMBER_LOGOFF',
  onAgentPause: 'QUEUEMEMBER_PAUSE',
  onAgentUnpause: 'QUEUEMEMBER_UNPAUSE',
  onAgentPenalty: 'QUEUEMEMBER_PENALTY',
  onDialerStart: 'DIALER_CALLSTART',
  onDialerConnect: 'DIALER_CALLCONNECT',
  onDialerEnd: 'DIALER_CALLEND',
  onDialerStatus: 'DIALER_STATUS',
  onCallRouteChange: 'CALL_ROUTECHANGE',
  onCallConnect: 'QUEUEMEMBER_CALLCONNECT',
  onCallAttempt: 'QUEUEMEMBER_CALLATTEMPT',
  onCallReject: 'QUEUEMEMBER_CALLREJECT',
  onCallDump: 'QUEUEMEMBER_CALLDUMP',
  onCallStart: 'QUEUEMEMBER_CALLSTART',
  onCallEnd: 'QUEUEMEMBER_CALLEND',
  onQueueJoin: 'QUEUECALLER_JOIN',
  onQueueAbandon: 'QUEUECALLER_ABANDON',
  onQueueReload: 'RELOAD',
  onAgentChatConnect: 'CONNECT',
  onAgentChatDisconnect: 'DISCONNECT',
  onFinish: 'ONFINISH',
  onOffer: 'ONOFFER',
  onAccept: 'ONACCEPT',
  onReject: 'ONREJECT',
  onTransfer: 'ONTRANSFER',
  onPendingChat: 'ONPENDINGCHAT',
  extensionsUpdate: 'EXTENSIONS_UPDATE',
  onIvrHangup: 'IVR_HANGUP'
}
