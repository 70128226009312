const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Consulta de Agentes' }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v2/agentes/tempo-logado',
  component: () => import('@/modules/agents/views/AgentTimeLogged.vue'),
  meta: {
    title: 'Visão consolidada do agente',
    breadcrumbs: baseBreadcrumbs({ label: 'Tempo logado' }),
    showFilterButton: true
  }
}, {
  path: '/v2/agentes/tempo-logado-diariamente',
  component: () => import('@/modules/agents/views/AgentTimeLoggedDaily.vue'),
  meta: {
    title: 'Visão consolidada do agente por dia',
    breadcrumbs: baseBreadcrumbs({ label: 'Tempo logado por dia' }),
    showFilterButton: true
  }
}]
