const getTokenJWT = window.getTokenJWT
const api = window.api

const PREFERENCES_RESET = 'PREFERENCES_RESET'
const PREFERENCES_ERROR = 'PREFERENCES_ERROR'
const PREFERENCES_SUCCESS = 'PREFERENCES_SUCCESS'
const PREFERENCES_LOADING = 'PREFERENCES_LOADING'

const state = {
  loaded: false,
  loading: false,

  data: {
    tab: '',
    views: {},
    onlineConfig: {},
    activeQueues: [],
    allowedQueues: [],
    allowedAgents: [],
    restrictedAccess: false
  }
}

const actions = {
  async loadPreferences ({ commit, getters }) {
    const token = await getTokenJWT().catch(() => null)
    if (!token) return

    if (getters.isLoading) return
    commit(PREFERENCES_LOADING)

    try {
      const result = await api.get('/cc/user-preferences')
      commit(PREFERENCES_SUCCESS, result)
    } catch (err) {
      commit(PREFERENCES_ERROR, err)
    }
  },

  async savePreferences ({ commit, state }, body) {
    try {
      commit(PREFERENCES_LOADING)
      const result = await api.put('/cc/user-preferences', { body })
      commit(PREFERENCES_SUCCESS, result)
    } catch (err) {
      commit(PREFERENCES_ERROR, err)
    }
  },

  async resetPreferences ({ commit }) {
    commit(PREFERENCES_RESET, {
      views: {},
      onlineConfig: {},
      activeQueues: [],
      allowedQueues: [],
      allowedAgents: []
    })
  },

  reloadPreferences ({ dispatch }) {
    setTimeout(() => (
      dispatch('loadPreferences', { renew: true })
    ), 300)
  }
}

const getters = {
  isLoaded: (state) => Boolean(state.loaded),
  isLoading: (state) => Boolean(state.loading),
  restrictedAccess: (state) => state.data.restrictedAccess,
  accessCampaings: (state) => state.data.accessCampaings,

  queues: (state) => {
    const allowedQueues = state?.data?.allowedQueues
    return Array.isArray(allowedQueues) ? [...allowedQueues] : []
  },

  agents: (state) => {
    const allowedAgents = state?.data?.allowedAgents
    return Array.isArray(allowedAgents) ? [...allowedAgents] : []
  },

  ivrCallsView: (state) => {
    const ivrCallsView = state?.data?.views?.ivrCallsView
    return ivrCallsView || {}
  },

  selected: (state) => {
    const activeQueues = state?.data?.activeQueues
    return Array.isArray(activeQueues) ? structuredClone(activeQueues) : []
  },

  orderQueues: (state) => {
    const orderQueues = state?.data?.onlineConfig?.orderQueues
    return Array.isArray(orderQueues) ? structuredClone(orderQueues) : []
  },

  tabGeneral: (state) => {
    const tab = state?.data?.onlineConfig?.generalDashboard?.tab
    return tab
  },

  customDashboard: (state) => {
    const customDashboard = state?.data?.onlineConfig?.customDashboard
    return customDashboard || {}
  },

  userTabs: (state) => {
    const tabs = state?.data?.tabs
    return Array.isArray(tabs) ? [...tabs] : []
  },

  userPermissions: (state) => {
    const permissions = state?.data?.permissions
    return Array.isArray(permissions) ? [...permissions] : []
  }
}

const mutations = {
  [PREFERENCES_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [PREFERENCES_SUCCESS] (state, { data }) {
    Object.assign(state, {
      data,
      loaded: true,
      loading: false
    })
  },

  [PREFERENCES_ERROR] (_, err) {
    console.log(err)
  },

  [PREFERENCES_RESET] (state, data) {
    Object.assign(state, {
      data,
      loaded: false,
      loading: false
    })
  }
}

const middlewares = {
  'preferencesStore/PREFERENCES_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('preferencesStore/loadPreferences', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}
