import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePreferencesStore = defineStore('preferences', () => {
  const route = ref('')
  const user = ref({})
  const activeQueues = ref([])
  const allowedQueues = ref([])
  const onlineConfig = ref({})
  const permissions = ref([])
  const tabs = ref([])
  const restrictedAccess = ref(false)
  const api = window.api

  const signOut = () => {
    window.signOut().catch(() => null).then(() => {
      window.location.href = '/acesso/entrar'
    })
  }

  const getPreferences = async () => {
    const user = await window.getAuthSession()
    user.value = user

    const result = await api.get('/cc/user-preferences')
    if (!result?.data) return null
    restrictedAccess.value = result?.data.restrictedAccess
    activeQueues.value = result?.data.activeQueues
    allowedQueues.value = result?.data.allowedQueues
    onlineConfig.value = result?.data.onlineConfig
    permissions.value = result?.data.permissions
    tabs.value = result?.data.tabs

    return Object.assign({}, user, result.data)
  }

  return {
    user,
    tabs,
    permissions,
    route,
    activeQueues,
    allowedQueues,
    restrictedAccess,
    signOut,
    getPreferences
  }
})
