import { markRaw, nextTick } from 'vue'
import DashboardsLogo from '@/assets/icons/DashboardsLogo.vue'
import IconPhone from '@/assets/icons/IconPhone.vue'
import ChatsLogo from '@/assets/icons/ChatsLogo.vue'
import AgentsLogo from '@/assets/icons/AgentsLogo.vue'
import SettingsLogo from '@/assets/icons/SettingsLogo.vue'
import ChangeLogIcon from '@/assets/icons/ChangeLogIcon.vue'
import ApiLogo from '@/assets/icons/ApiLogo.vue'
import pkg from '@/../package.json'

export default (session) => {
  if (!session) session = {}
  session.tabs = Array.isArray(session?.tabs) ? session.tabs : []
  session.permissions = Array.isArray(session?.permissions) ? session.permissions : []
  session.accessCampaings = Boolean(session.accessCampaings)
  session.restrictedAccess = Boolean(session.restrictedAccess)

  const dashboardMenu = getDashboardMenu(session)
  const callsMenu = getCallsMenu(session)
  const talksMenu = getTalksMenu(session)
  const agentsMenu = getAgentsMenu(session)
  const configMenu = getConfigMenu(session)

  const menu = []

  if (dashboardMenu.length) menu.push({ label: 'Dashboards', menu: dashboardMenu, icon: markRaw(DashboardsLogo), active: dashboardMenu.find(({ active }) => active), align: 'left' })
  if (callsMenu.length) menu.push({ label: 'Chamadas', menu: callsMenu, icon: markRaw(IconPhone), active: callsMenu.find(({ active }) => active), align: 'left' })
  if (talksMenu.length) menu.push({ label: 'Conversas', menu: talksMenu, icon: markRaw(ChatsLogo), active: talksMenu.find(({ active }) => active), align: 'left' })
  if (agentsMenu.length) menu.push({ label: 'Agentes', menu: agentsMenu, icon: markRaw(AgentsLogo), active: agentsMenu.find(({ active }) => active), align: 'left' })
  if (configMenu.length) menu.push({ label: 'Configurações', menu: configMenu, icon: markRaw(SettingsLogo), active: configMenu.find(({ active }) => active), align: 'left' })
  menu.push({ separator: true })

  menu.push(mapMenu({
    link: '/v1/api-docs',
    icon: markRaw(ApiLogo),
    others: [
      '/v1/api-docs/contatos-discador',
      '/v1/api-docs/resultados-atendimentos',
      '/v1/api-docs/agentes-pabx',
      '/v1/api-docs/bots-messages'
    ],
    align: 'right'
  }))

  menu.push({ link: '/v1/changelog', icon: markRaw(ChangeLogIcon), label: pkg.version, active: isMatch('/v1/changelog'), align: 'right' })

  return menu
}

const dashboardMenu = [{
  label: 'Agentes',
  link: '/v1/dashboards/agents',
  restrictedAccess: true
}, {
  label: 'Atendimentos',
  link: '/v2/dashboards/atendimentos'
}, {
  label: 'Campanhas',
  link: '/v1/dashboards/discadores',
  others: ['/v1/dashboards/*/acompanhar', '/v1/dashboards/*/contatos', '/v1/dashboards/*/importar'],
  accessCampaings: true
}, {
  label: 'Filas',
  link: '/v1/dashboards/queues',
  restrictedAccess: true
}, {
  label: 'Relatórios',
  link: '/v1/dashboards/relatorios'
}]

const configMenu = [{
  label: 'Agentes',
  link: '/v1/agentes',
  access: 'adminAgents',
  others: ['/v1/agentes/*/editar', '/v1/agentes/adicionar']
}, {
  label: 'Banners',
  link: '/v1/banners',
  access: 'adminBanners'
}, {
  label: 'Brokers de Atendimento',
  link: '/v1/brokers-de-atendimentos',
  access: 'adminBots',
  others: ['/v1/brokers-de-atendimentos/*/editar', '/v1/brokers-de-atendimentos/adicionar']
}, {
  label: 'Cadastro de Áudios',
  link: '/v1/audios',
  access: 'adminSounds',
  others: ['/v1/audios/*/editar', '/v1/audios/adicionar']
}, {
  label: 'DenyLists',
  link: '/v1/denylists',
  access: 'adminQueues',
  others: ['/v1/denylists/*/editar', '/v1/denylists/adicionar']
}, {
  label: 'Filas',
  link: '/v1/filas',
  access: 'adminQueues',
  others: ['/v1/filas/*/editar', '/v1/filas/adicionar']
}, {
  label: 'Filas de Fonação',
  link: '/v1/filas-de-fonacao',
  access: 'adminQueues',
  others: ['/v1/filas-de-fonacao/*/editar', '/v1/filas-de-fonacao/adicionar']
}, {
  label: 'Fluxos de Conversas',
  link: '/v1/fluxos-conversas',
  access: 'adminBots',
  others: ['/v1/fluxos-conversas/*/editar', '/v1/fluxos-conversas/adicionar']
}, {
  label: 'Motivos de Pausas',
  link: '/v1/motivos-de-pausa',
  access: 'adminPauseReasons',
  others: ['/v1/motivos-de-pausa/*/editar', '/v1/motivos-de-pausa/adicionar']
}, {
  label: 'Números de Entrada',
  link: '/v1/numeros-de-entrada',
  access: 'adminEntryNumbers',
  others: ['/v1/numeros-de-entrada/*/editar', '/v1/numeros-de-entrada/adicionar']
}, {
  label: 'Perfiladores',
  link: '/v1/perfiladores',
  access: 'adminPerfilator',
  others: ['/v1/perfiladores/*/editar', '/v1/perfiladores/adicionar']
}, {
  label: 'Planos e Tarifas',
  link: '/v1/planos',
  access: 'adminBillingCalls',
  others: ['/v1/planos/*/editar', '/v1/planos/adicionar']
}, {
  label: 'Ramais Administrativos',
  link: '/v1/ramais-administrativos',
  access: 'adminExtensions',
  others: ['/v1/ramais-administrativos/*/editar', '/v1/ramais-administrativos/adicionar']
}, {
  label: 'Ramais de Callcenter',
  link: '/v1/ramais-callcenter',
  access: 'adminExtensions',
  others: ['/v1/ramais-callcenter/*/editar', '/v1/ramais-callcenter/adicionar']
}, {
  label: 'Respostas Rápidas',
  link: '/v1/respostas-rapidas',
  access: 'adminBots',
  others: ['/v1/respostas-rapidas/*/editar', '/v1/respostas-rapidas/adicionar']
}, {
  label: 'Rotas de Saída',
  link: '/v1/rotas-de-saida',
  access: 'adminRoutes',
  others: ['/v1/rotas-de-saida/*/editar', '/v1/rotas-de-saida/adicionar']
}, {
  label: 'Tokens',
  link: '/v1/tokens',
  access: 'adminTokens',
  others: ['/v1/tokens/*/editar', '/v1/tokens/adicionar']
}, {
  label: 'Transcrição de Chamadas',
  link: '/v1/transcricoes-de-chamadas',
  access: 'adminTranscriptions',
  others: [
    '/v1/transcricoes-de-chamadas/*/editar',
    '/v1/transcricoes-de-chamadas/adicionar'
  ]
},
// {
//   label: 'Troncos',
//   link: '/v1/troncos',
//   access: 'adminTrunkings',
//   others: ['/v1/troncos/*/editar', '/v1/troncos/adicionar']
// },
{
  label: 'URAs',
  link: '/v1/uras',
  access: 'adminUras',
  others: ['/v1/uras/*/editar', '/v1/uras/adicionar']
}, {
  label: 'Usuários',
  link: '/v1/usuarios',
  access: 'adminUsers',
  others: ['/v1/usuarios/*/editar', '/v1/usuarios/adicionar']
}]

const agentsMenu = [{
  label: 'Detalhado',
  link: '/v1/agentes/detalhado'
}, {
  label: 'Frequência',
  link: '/v1/agentes/frequencia-do-agente'
}, {
  label: 'Frequência/Dia',
  link: '/v1/agentes/frequencia-dos-agentes'
}, {
  label: 'Ociosidade',
  link: '/v1/agentes/ociosidade'
}, {
  label: 'Pausas',
  link: '/v1/agentes/pausas'
}, {
  label: 'Produtividade',
  link: '/v1/agentes/produtividade'
}, {
  label: 'Ranking',
  link: '/v1/agentes/ranking'
}, {
  label: 'Sessões',
  link: '/v1/agentes/sessoes'
}, {
  label: 'Sessões/Eventos',
  link: '/v1/agentes/sessoes-de-eventos'
}, {
  label: 'Visão Geral',
  link: '/v1/agentes/visao-geral'
}, {
  label: 'Tempo logado',
  link: '/v2/agentes/tempo-logado'
}, {
  label: 'Tempo logado/Dia',
  link: '/v2/agentes/tempo-logado-diariamente'
}]

const callsMenu = [{
  label: 'Chamadas geral',
  link: '/v1/chamadas'
}, {
  separator: true
}, {
  label: 'Chamadas por:',
  title: true
}, {
  label: 'Abandono',
  link: '/v1/chamadas/por-abandono'
}, {
  label: 'Atendimentos por Horário',
  link: '/v1/atendimento/agentes'
}, {
  label: 'Chamadas da Ura',
  link: '/v1/atendimento/uras'
}, {
  label: 'Desempenho',
  link: '/v1/chamadas/por-desempenho'
}, {
  label: 'Duração',
  link: '/v1/chamadas/por-duracao'
}, {
  label: 'Filas',
  link: '/v1/chamadas/por-fila'
}, {
  label: 'Horário',
  link: '/v1/chamadas/por-periodo'
}, {
  label: 'Localidade',
  link: '/v1/chamadas/por-localidade'
}, {
  label: 'Número',
  link: '/v1/chamadas/por-numero'
}, {
  label: 'Operadora',
  link: '/v1/chamadas/por-operadora'
}, {
  label: 'Tipo',
  link: '/v1/chamadas/por-tipo'
}, {
  label: 'Tronco',
  link: '/v1/chamadas/por-tronco'
}, {
  label: 'Causa',
  link: '/v2/chamadas/por-causa'
}, {
  label: 'Requisições',
  link: '/v2/chamadas/por-requisicoes'
}]

const talksMenu = [{
  label: 'Atendimento geral de chat',
  link: '/v1/conversas',
  access: 'chats'
}, {
  separator: true,
  access: 'chats'
}, {
  label: 'Atendimentos de chat por:',
  title: true
}, {
  label: 'Agente',
  link: '/v1/conversas/por-agentes',
  access: 'chats'
}, {
  label: 'Broker',
  link: '/v1/conversas/por-broker',
  access: 'chats'
}, {
  label: 'Fila',
  link: '/v1/conversas/por-filas',
  access: 'chats'
}, {
  label: 'Template',
  link: '/v1/conversas/por-template',
  access: 'chats'
}, {
  separator: true,
  access: 'emails'
}, {
  label: 'Atendimento de email',
  link: '/v1/emails',
  access: 'emails'
}, {
  label: 'Mensagens de Email',
  link: '/v1/mensagens',
  access: 'emails'
}]

const getDashboardMenu = ({ tabs, restrictedAccess, accessCampaings }) => {
  if (!tabs.includes('dashboards')) return []

  const menu = dashboardMenu.map(mapMenu)
  if (!restrictedAccess) return menu

  return menu.filter((item) => {
    if (item.restrictedAccess) return true
    return item.accessCampaings && accessCampaings
  })
}

const getConfigMenu = ({ permissions }) => (
  configMenu
    .filter(({ access }) => permissions.includes(access))
    .map(mapMenu)
)

const getAgentsMenu = ({ tabs }) => {
  if (!tabs.includes('agents')) return []
  return agentsMenu.map(mapMenu)
}

const getCallsMenu = ({ tabs }) => {
  if (!tabs.includes('calls')) return []
  return callsMenu.map(mapMenu)
}

const getTalksMenu = ({ tabs }) => (
  talksMenu
    .filter(({ access }) => tabs.includes(access))
    .map(mapMenu)
)

export const isMatch = (pattern) => {
  if (!pattern) return false
  const regex = new RegExp('^' + removeTrailingSlash(pattern).replace(/\*/g, '[^/]+') + '$')
  return regex.test(removeTrailingSlash(window.location.pathname))
}

const removeTrailingSlash = (route) => {
  let baseRoute = route.split('?').shift()
  if (baseRoute.endsWith('/')) baseRoute = baseRoute.substr(0, baseRoute.length - 1)
  return baseRoute
}

const mapMenu = (item) => {
  const menu = Object.assign({}, item)
  menu.active = isMatch(menu.link)
  if (!menu.active) menu.active = Array.isArray(menu.others) ? Boolean(menu.others.find(isMatch)) : false
  return menu
}

const outClick = (menu, e) => {
  const withinNav = e.target.closest('.navbar')

  const oldForcedMenu = document.querySelector('a.menu.force-active,a.menu-user.force-active')
  if (!withinNav) closeAllDropdowns(menu, oldForcedMenu)
}

const getTarget = (el, classNames) => {
  while (true) {
    for (const className of classNames) {
      if (el.classList.contains(className)) {
        return el
      }
    }
    el = el.parentNode
  }
}

const onClickItem = async (e, menu) => {
  e.preventDefault()

  const isVonixLogo = (
    (e.target.tagName === 'svg' && e.target.classList.contains('vonix-logo')) ||
    (e.target.tagName === 'path' && e.target.parentNode.classList.contains('vonix-logo'))
  )

  document.querySelectorAll('a.link-menu').forEach((linkMenu) => {
    linkMenu.classList.remove('force-active')
  })

  if (isVonixLogo) return

  const target = getTarget(e.target, ['menu', 'menu-user'])
  const isActive = target.classList.contains('active')
  const isForcedActive = target.classList.contains('force-active')
  if (isForcedActive) target.classList.toggle('force-active')
  closeAllDropdowns(menu)

  if (isActive) return

  menu.value.pathActive = false
  await nextTick()
  target.classList.toggle('active')

  const dropdown = target.nextElementSibling

  if (dropdown && dropdown.classList.contains('dropdown')) {
    let maxHeight = String(dropdown.style.maxHeight || '').trim().replace('px', '').replace('em', '')
    maxHeight = maxHeight && !isNaN(maxHeight) ? Number(maxHeight) : 0

    if (maxHeight > 0) return closeAllDropdowns(menu)

    closeAllDropdowns(menu, dropdown)
    dropdown.style.maxHeight = '70vh'
    dropdown.style.borderLeft = '1px solid #c5c5c5'
    dropdown.style.borderRight = '1px solid #c5c5c5'
    dropdown.style.borderBottom = '1px solid #c5c5c5'
  }
}

const subItemClick = async (e, menu) => {
  const parentNode = e.target.parentNode
  const linkElement = e.target.closest('a.link-menu')

  if (parentNode.classList.contains('dropdown')) {
    const menuElement = parentNode.parentNode.querySelector('a.menu')
    menuElement.classList.toggle('force-active')
    menuElement.classList.toggle('active')
  }

  if (linkElement) {
    document.querySelectorAll('a.link-menu').forEach((linkMenu) => {
      linkMenu.classList.remove('force-active')
    })
    linkElement.classList.toggle('force-active')
  }

  closeAllDropdowns(menu, parentNode)

  const href = getTarget(e.target, ['submenu', 'link-menu']).getAttribute('href')
  goToRoute(e, href)
}

const goToRoute = (e, href) => {
  if (isMatch(href)) return

  const windowPathName = window.location.pathname
  const currentV1 = windowPathName.startsWith('/v1') || windowPathName.startsWith('/acesso')
  const currentV2 = windowPathName.startsWith('/v2')
  const isV1link = href.startsWith('/v1')
  const isV2link = href.startsWith('/v2')

  if (currentV1 && isV2link) return
  if (currentV2 && isV1link) return
  if (!isV1link && !isV2link) return

  e.preventDefault()
  window.broker.emit('message', { event: 'CLIENT_MENU_ROUTE', payload: { href } })
}

const closeAllDropdowns = (menu, except = null) => {
  document.querySelectorAll('.dropdown').forEach((dropdown) => {
    if (dropdown !== except) {
      const oldMenu = dropdown.parentNode.querySelector('a.menu.active,a.menu-user.active')
      if (oldMenu) oldMenu.classList.toggle('active')
      const oldForcedMenu = dropdown.parentNode.querySelector('a.menu.force-active,a.menu-user.force-active')
      if (oldForcedMenu && oldForcedMenu !== except) oldForcedMenu.classList.toggle('force-active')
    }
    dropdown.style.maxHeight = '0'
    dropdown.style.borderLeft = null
    dropdown.style.borderRight = null
    dropdown.style.borderBottom = null
  })

  if (except === null) { menu.value.pathActive = true }
}

const goToDashboardAgents = (e, menu) => {
  menuHelpers.closeAllDropdowns(menu)
  menuHelpers.goToRoute(e, menu.value.list.at(0).menu.at(0).link)
  menu.value.pathActive = true
}

export const menuHelpers = { outClick, onClickItem, subItemClick, goToRoute, getTarget, closeAllDropdowns, goToDashboardAgents }
