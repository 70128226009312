import router from '@/router'

import {
  ROUTE_BLOCKED_ACCESS,
  ROUTE_DASHBOARD_QUEUES,
  ROUTE_DASHBOARD_MAILING,
  ROUTE_DASHBOARD_ATTENDANCE,

  ROUTE_DASHBOARD_MAILING_VIEW,
  ROUTE_DASHBOARD_MAILING_IMPORT,
  ROUTE_DASHBOARD_MAILING_CONTACTS
} from '@/modules/dashboards/constants'

import {
  ROUTE_AUTH_SIGN_IN,
  ROUTE_AUTH_SIGN_MFA,
  ROUTE_AUTH_PASSWORD_FORGOT,
  ROUTE_AUTH_PASSWORD_DEFINE,
  ROUTE_AUTH_PASSWORD_CHANGE
} from '@/modules/auth/constants'

import {
  ROUTE_SWAGGER_API_DOCS,
  ROUTE_SWAGGER_API_RESULTS,
  ROUTE_SWAGGER_API_CONTACTS,
  ROUTE_SWAGGER_API_RPC_AGENTS
} from '@/modules/swagger/constants'

import {
  ROUTE_CHANGELOG_LIST
} from '@/modules/changelogs/constants'

import {
  ROUTE_FINANCE_LIST
} from '@/modules/finance/constants'

const publicRoutes = [
  ROUTE_FINANCE_LIST,
  ROUTE_CHANGELOG_LIST,
  ROUTE_SWAGGER_API_DOCS,
  ROUTE_SWAGGER_API_RESULTS,
  ROUTE_SWAGGER_API_CONTACTS,
  ROUTE_SWAGGER_API_RPC_AGENTS
]

const authRoutes = [
  ROUTE_AUTH_SIGN_IN,
  ROUTE_AUTH_SIGN_MFA,
  ROUTE_AUTH_PASSWORD_DEFINE,
  ROUTE_AUTH_PASSWORD_FORGOT,
  ROUTE_AUTH_PASSWORD_CHANGE
]

const CampaingRoutes = [
  ROUTE_DASHBOARD_MAILING_VIEW,
  ROUTE_DASHBOARD_MAILING_CONTACTS,
  ROUTE_DASHBOARD_MAILING_IMPORT
]

export default () => (store) => {
  let isLoaded, userTabs, userPermissions

  const loadPreferences = () => {
    isLoaded = store.getters['preferencesStore/isLoaded']
    userTabs = store.getters['preferencesStore/userTabs']
    userPermissions = store.getters['preferencesStore/userPermissions']
  }
  const waitPreferences = async () => {
    store.dispatch('preferencesStore/resetPreferences')
    store.dispatch('preferencesStore/loadPreferences')

    return new Promise((resolve) => {
      const interval = setInterval(() => {
        loadPreferences()
        if (!isLoaded) return
        clearInterval(interval)
        resolve()
      }, 500)
    })
  }

  router.beforeEach(async (route, from, next) => {
    const hasCognitoUser = store.getters.hasCognitoUser
    const isAuthenticated = store.getters.isAuthenticated
    const redirectAuthRoute = checkRedirectAuthRoute(route, isAuthenticated, hasCognitoUser)

    if (redirectAuthRoute) {
      if (publicRoutes.includes(route.name)) return next()

      store.dispatch('ROUTE_CHANGED', redirectAuthRoute)
      const isRedirectQueue = ['/dashboards/queues'].includes(route.fullPath)
      const query = { redirect: !isRedirectQueue ? route.fullPath : undefined }

      return next({ name: redirectAuthRoute, query })
    }

    if (isAuthenticated) {
      loadPreferences()
      const isNewLogin = from.name === ROUTE_AUTH_SIGN_IN
      if (!isLoaded || isNewLogin) await waitPreferences()

      const tabBlocked = route.meta.tab && !userTabs.includes(route.meta.tab)
      const permissionBlocked = route.meta.permission && !userPermissions.includes(route.meta.permission)
      const isBlocked = tabBlocked || permissionBlocked || checkRestrictRoute(route, store) || checkQueuesPermitted(route, store)
      if (isBlocked) return next({ name: ROUTE_BLOCKED_ACCESS })
    }

    store.dispatch('ROUTE_CHANGED', route.name)
    next()
  })
}

const checkRestrictRoute = (route, store) => {
  const restrictedAccess = store.getters['preferencesStore/restrictedAccess']
  const accessCampaings = store.getters['preferencesStore/accessCampaings']

  const restrictRoute = [ROUTE_DASHBOARD_ATTENDANCE, ROUTE_DASHBOARD_MAILING, ROUTE_DASHBOARD_MAILING_IMPORT, ROUTE_DASHBOARD_MAILING_CONTACTS, ROUTE_DASHBOARD_MAILING_VIEW]
  const isRestrictedRoute = restrictRoute.includes(route.name)
  const isAccessCampaings = accessCampaings && route.name.startsWith(ROUTE_DASHBOARD_MAILING)
  if (isAccessCampaings) return false
  if (restrictedAccess && isRestrictedRoute) return true

  return false
}

const checkRedirectAuthRoute = (route, isAuthenticated, hasCognitoUser) => {
  const isAuthRoute = authRoutes.includes(route.name)

  const shouldRedirectToMain = isAuthenticated && isAuthRoute
  if (shouldRedirectToMain) return ROUTE_DASHBOARD_QUEUES

  if (isAuthenticated) return
  if (!isAuthRoute) return ROUTE_AUTH_SIGN_IN

  const denyRouteMFA = route.name === ROUTE_AUTH_SIGN_MFA && !hasCognitoUser
  if (denyRouteMFA) return ROUTE_AUTH_SIGN_IN

  const denyRouteDefinePassword = route.name === ROUTE_AUTH_PASSWORD_DEFINE && !hasCognitoUser
  if (denyRouteDefinePassword) return ROUTE_AUTH_SIGN_IN

  const denyRouteChangePassword = route.name === ROUTE_AUTH_PASSWORD_CHANGE && !route.query?.email
  if (denyRouteChangePassword) return ROUTE_AUTH_SIGN_IN
}

const checkQueuesPermitted = (route, store) => {
  const isMailingRoute = CampaingRoutes.includes(route.name)
  if (!isMailingRoute) return
  const preferenceQueues = store.getters['preferencesStore/queues']
  const queueIndex = 3
  const routeQueue = route.path.split('/')[queueIndex]

  const queueInPreference = preferenceQueues.find(({ id }) => routeQueue === id)
  const queueIsPermitted = queueInPreference?.id

  if (!queueIsPermitted) return true
  return false
}
