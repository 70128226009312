import { createRouter, createWebHistory } from 'vue-router'
import pagesRoutes from '@/modules/pages/routes.js'
import dashboardRoutes from '@/modules/dashboards/routes.js'
import callRoutes from '@/modules/calls/routes.js'
import agentRoutes from '@/modules/agents/routes.js'
import requestRoutes from '@/modules/requests/routes.js'

let router = null
const api = window.api

export default (store) => {
  if (!router) router = makeRouter()

  router.beforeEach(async (route, from, next) => {
    const session = await window.getAuthSession()
    const isAuthenticated = Boolean(session)

    if (isAuthenticated) {
      const preferences = await api.get('/cc/user-preferences')
      const restrictedAccess = preferences?.data.restrictedAccess
      const tabMatch = route.path.match(/\/v2\/([^/]+)/)
      const tab = translateTab[tabMatch[1]]
      const tabBlocked = tab && !preferences.data.tabs.includes(tab)
      if (restrictedAccess || tabBlocked) window.location.href = '/v1/acesso-bloqueado'
      return next()
    }

    window.location.href = '/acesso/entrar'
  })

  return router
}

const makeRouter = () => {
  const history = createWebHistory('/')

  return createRouter({
    history,
    routes: [].concat(
      pagesRoutes,
      dashboardRoutes,
      callRoutes,
      agentRoutes,
      requestRoutes,
      {
        path: '/*',
        redirect: { path: '/v2/dashboards/one' }
      }
    )
  })
}

const translateTab = {
  chamadas: 'calls'
}
