import formatElapsedSeconds from '@/utils/formatElapsedSeconds'
import moment from 'moment-timezone'
import numeral from 'numeral'
import 'numeral/locales/pt-br'
numeral.locale('pt-br')

export const ONLINE = 'ONLINE'
export const PAUSED = 'PAUSED'
export const OFFLINE = 'OFFLINE'
export const ONGOING = 'ONGOING'
export const RINGING = 'RINGING'
export const DIALING = 'DIALING'
export const OFFERING = 'OFFERING'
export const ENQUEUED = 'ENQUEUED'
export const CONNECTED = 'CONNECTED'
export const DISCONNECT = 'disconnect'
export const ONTHEPHONE = 'ONTHEPHONE'
export const UNAVAILABLE = 'UNAVAILABLE'

export const statusNames = {
  [OFFLINE]: 'Desconectado',
  [ONLINE]: 'Disponível',
  [RINGING]: 'Tocando',
  [DIALING]: 'Discando',
  [PAUSED]: 'Pausado',
  [DISCONNECT]: 'Desconectado',
  [ONTHEPHONE]: 'em Atendimento',
  [UNAVAILABLE]: 'Indisponível',
  [ONGOING]: 'em Atendimento',
  [OFFERING]: 'em Oferecimento'
}

const calcPercent = (count, total) => count && total ? Math.floor((count / total) * 100) : 0
const todayUnix = Number(moment().startOf('day').unix())

export const formatDate = (val) => val ? moment(val).format('DD/MM/YYYY') : '-'
export const formatDateTime = (val) => val ? moment(val).format('DD/MM/YYYY - HH:mm:ss') : '-'
export const formatTimestamp = (val) => val ? moment.unix(val).format('HH:mm:ss') : '-'
export const formatTimestampToDate = (val) => val ? moment.unix(val).format('DD/MM/YYYY') : '-'
export const formatTimestampDateTime = (val) => val ? moment.unix(val).format('DD-MM-YYYY - HH:mm:ss') : '-'

export const formatTimestampDayHours = (val) => {
  if (!val) return '-'
  const oneDay = 86400

  if (val > oneDay) {
    const duration = moment.duration(val, 'seconds')
    const hours = Math.floor(duration.asHours())
    const minutes = duration.minutes()
    const secs = duration.seconds()
    const toFormatTime = (time) => time.toString().padStart(2, '0')

    return `${hours}:${toFormatTime(minutes)}:${toFormatTime(secs)}`
  }

  return formatHours(val)
}

export const formatHours = (val) => {
  if (!val) return '-'

  const duration = moment.duration(val, 'seconds')
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
}

export const formatSchedule = (timestamp) => {
  return timestamp < todayUnix ? formatTimestampDateTime(timestamp) : formatTimestamp(timestamp)
}

export const holdCalls = (queue, calls) => {
  const queueCalls = Array.isArray(queue.calls) ? queue.calls : []

  return queueCalls
    .filter(onlyHoldCalls.bind(this, calls))
    .map(reduceHoldCall.bind(this, calls))
    .sort(byCreatedAtDesc)
}

export const makeAgentDetails = (agentId, state) => {
  const agent = state.agents[agentId]
  const { summary, summaries } = agent

  const { inCompleted, outCompleted, autoCompleted, outDiscarded } = summary
  const callSecs = (summary.ringSecs || 0) + (summary.callSecs || 0)
  const pausedSecs = summary.pauseSecs

  let queueCallsCount = 0

  const queues = Object.values(summaries).filter(({ queueId }) => state.queues[queueId]).map((sum) => {
    const callsCount = (sum.inCompleted || 0) + (sum.outCompleted || 0) + (sum.autoCompleted || 0) + (sum.outDiscarded || 0)
    const completedCount = (sum.inCompleted || 0) + (sum.outCompleted || 0) + (sum.autoCompleted || 0)
    const discardedCount = sum.outDiscarded || 0
    queueCallsCount += callsCount

    return {
      id: sum.queueId,
      name: state.queues[sum.queueId].name,
      callSecs: (sum.callSecs || 0) + (sum.inRingSecs || 0) + (sum.ringSecs || 0),

      callsCount,

      completedCount,
      completedPercent: calcPercent(completedCount, callsCount),

      discardedCount,
      discardedPercent: calcPercent(discardedCount, callsCount)
    }
  })

  return {
    name: agent.name,
    calls: { inCompleted, outCompleted, autoCompleted, outDiscarded },
    online: { callSecs, pausedSecs, loginAt: agent.loginAt },
    queues: JSON.stringify(queues.map((queue) => ({ ...queue, callsPercent: calcPercent(queue.callsCount, queueCallsCount) })))
  }
}

const sameQueue = (queueId, queue) => queueId === queue
const isOut = ({ direction }) => direction === 'AUTO'
const reduceDialingCalls = (queue, calls) => calls.reduce((acc, call) => (sameQueue(call.queueId, queue) && isOut(call) ? ++acc : acc), 0)
const reduceOnDialCalls = (queue, calls) => calls.reduce((acc, call) => (sameQueue(call.queueId, queue) && isOut(call) && call.status === 'DIALING' ? ++acc : acc), 0)

export const dashQueues = (state, userQueues) => (
  userQueues.filter((queueId) => {
    const queue = state.queues[queueId]
    if (!queue) return false

    const isUraActive = Boolean(queue.uraId)
    if (isUraActive) return true

    const listAgents = Array.isArray(queue.agents) ? queue.agents : []
    const agents = listAgents.filter((id) => state.agents[id]?.status !== OFFLINE)

    return Boolean(agents.length > 0)
  })
)

export const makeOnlineQueue = (state, pauses, queueId, showAgents) => {
  const queue = Object.assign({}, state.queues[queueId])
  const summary = queue?.summary ?? {}
  const abandonedCount = summary.inAbandoned ?? 0
  const descardedCount = (summary.outDiscarded ?? 0) + (summary.autoDiscarded ?? 0)
  const completedCount = (summary.inCompleted ?? 0) + (summary.autoCompleted ?? 0) + (summary.outCompleted ?? 0)
  const callsCount = completedCount + descardedCount + abandonedCount
  const directionAuto = Boolean(queue.directionAuto)
  const dialerSpeed = queue.dialerSpeed || 0
  const dialerStatus = queue.dialerStatus || ''
  const dialerMaxTrunks = queue.dialerMaxTrunks || 0
  const lcrProfileId = queue.lcrProfileId
  const contacts = getPendingContacts(queue)
  const listAgents = Array.isArray(queue.agents) ? queue.agents : []
  const agents = listAgents.sort(byCodeAsc)
    .map((id) => mapQueueAgent(state.calls, queue.id, callsCount, pauses, state.agents[id], state.extensions, state.versionVP, showAgents))
    .filter(v => v)

  const queueCalls = Array.isArray(queue.calls) ? queue.calls : []
  const holdCalls = queueCalls
    .filter(onlyHoldCalls.bind(this, state.calls))
    .map(reduceHoldCall.bind(this, state.calls))
    .sort(byCreatedAtDesc)

  let ongoingCalls = queueCalls
    .filter(onlyOngoingCalls.bind(this, state.calls))
    .map(reduceOngoingCall.bind(this, state.calls))
    .sort(byCreatedAtDesc)

  const isUraActive = Boolean(queue.uraId)
  if (isUraActive) ongoingCalls = ongoingCalls.splice(0, 25).sort(byCreatedAtAsc)

  const onDial = reduceOnDialCalls(queue.id, Object.values(state.calls))
  const dialingTrunks = reduceDialingCalls(queue.id, Object.values(state.calls))

  const hasAgentsLoggedIn = Boolean(agents.length > 0)
  const queueValid = isUraActive || (hasAgentsLoggedIn && queue.active)
  if (!queueValid && !hasAgentsLoggedIn) return null

  const abandonedPercent = Number((callsCount ? abandonedCount / callsCount : 0).toFixed(3))
  const descardedPercent = Number((callsCount ? descardedCount / callsCount : 0).toFixed(3))
  const completedPercent = Number((1.000 - abandonedPercent - descardedPercent).toFixed(3))

  const completedTalkSecs = (summary.inCallSecs ?? 0) + (summary.autoCallSecs ?? 0)
  const completedHoldSecs = (summary.inHoldSecsCompleted ?? 0) + (summary.autoHoldSecsCompleted ?? 0)
  const abandonedHoldSecs = (summary.inHoldSecsAbandoned ?? 0) + (summary.autoHoldSecsAbandoned ?? 0)
  const tmeC = completedCount ? Math.floor(completedHoldSecs / completedCount) : 0
  const tmeA = abandonedCount ? Math.floor(abandonedHoldSecs / abandonedCount) : 0
  const tme = (completedCount + abandonedCount) ? Math.floor((completedHoldSecs + abandonedHoldSecs) / (completedCount + abandonedCount)) : 0
  const completedSecs = (summary.inCallSecs ?? 0) + (summary.outCallSecs ?? 0) + (summary.autoCallSecs ?? 0)
  const tma = completedCount ? Math.floor(completedSecs / completedCount) : 0

  return {
    id: queue.id,
    name: queue.name,

    tme,
    tma,
    tmeC,
    tmeA,
    callsCount,
    directionAuto,
    isUraActive,

    completedCount,
    completedPercent,
    completedHoldSecs,
    completedTalkSecs,

    descardedCount,
    descardedPercent,

    dialerSpeed,
    dialerStatus,
    dialerMaxTrunks,

    lcrProfileId,
    dialingTrunks,

    abandonedCount,
    abandonedPercent,
    abandonedHoldSecs,

    agents,
    holdCalls,
    ongoingCalls,

    countAgents: agents.length,
    countHoldCalls: holdCalls.length,

    onDial,

    contacts,

    ...checkSla(queue)
  }
}

const getIdleTime = (status, loginAt, lastCallAnsweredAt) => {
  if ([ONTHEPHONE, UNAVAILABLE, DIALING].includes(status)) return 0
  if (!lastCallAnsweredAt) return loginAt
  return lastCallAnsweredAt > loginAt ? lastCallAnsweredAt : loginAt
}

const getDurationTime = (idleTime, status, agent, inInternalCall) => {
  if (status === ONLINE) return 0
  if ((inInternalCall && [ONLINE, UNAVAILABLE].includes(agent.status))) return 0

  let statusTime = idleTime

  if (agent.pauseAt && agent.pauseAt > statusTime) statusTime = agent.pauseAt
  if (agent.lastPauseAt && agent.lastPauseAt > statusTime) statusTime = agent.lastPauseAt
  if (agent.talkingCallId && agent.talkingSince > statusTime) statusTime = agent.talkingSince
  if (agent.lastCallRejectedAt && agent.lastCallRejectedAt > statusTime) statusTime = agent.lastCallRejectedAt

  return statusTime
}

const genSummaryCallcenter = () => ({
  paused: 0,
  online: 0,
  ringing: 0,
  dialing: 0,
  talking: 0,

  inCallSecs: 0,
  outCallSecs: 0,
  autoCallSecs: 0,

  inAbandoned: 0,
  inCompleted: 0,
  outCompleted: 0,
  outDiscarded: 0,
  autoCompleted: 0,
  inCompletedSla: 0,

  onlineAgentCalls: 0,

  inHoldSecsAbandoned: 0,
  inHoldSecsCompleted: 0
})

const checkSla = (queueObj) => {
  const queue = queueObj || {}
  if (!queue.slaSecs) return { slaSecs: null, slaGood: null, slaPercent: null }

  const summary = queue.summary || {}
  const slaSecs = queue.slaSecs
  const slaPercent = queue.slaPercent || 1
  const inCompleted = summary.inCompleted || 0
  const inCompletedSla = summary.inCompletedSla || 0
  const slaCalc = Number((inCompleted && inCompletedSla ? inCompletedSla / inCompleted : 0).toFixed(3))
  const slaGood = inCompleted && inCompletedSla ? (slaCalc * 100) >= slaPercent : false
  return { slaSecs, slaGood, slaPercent, slaCalc }
}

const mapAgentCall = (calls, id) => {
  const call = calls[id]
  if (!call) return null

  return {
    id,
    callNumber: calls[id].callerNumber,
    callDirection: calls[id].direction
  }
}

export const makeOnlineAgents = (state, pauses, userQueues) => {
  const callcenter = genSummaryCallcenter()
  const calls = Object.values(state.calls).filter(({ status }) => status === ENQUEUED).sort(byCreatedAtDesc)
  callcenter.holdCallsActive = calls.filter(({ queueId }) => userQueues.includes(queueId)).length

  const queueCalls = Object
    .values(state.queues)
    .filter(({ id }) => userQueues.includes(id))
    .map(toQueueCall(callcenter, calls))
    .filter(({ calls }) => calls.length)

  const gelVal = (val) => val || 0

  const agents = Object.values(state.agents).map((agent) => {
    const summary = agent.summary ?? {}
    const rejections = summary.rejections || 0
    const completedCount = gelVal(summary.autoCompleted) + gelVal(summary.inCompleted) + gelVal(summary.outCompleted)
    const talkingCallId = agent.talkingCallId && state.calls[agent.talkingCallId] && state.calls[agent.talkingCallId].queueId ? agent.talkingCallId : null
    const talkingCall = { ...state.calls[talkingCallId] }
    const agentCalls = agent.calls.map((callId) => mapAgentCall(state.calls, callId)).filter(v => v)
    const membership = talkingCall.queueId ? agent.memberships[talkingCall.queueId] : null
    const penalty = membership?.penalty ?? 0
    const extensionStatus = state.extensions[agent.loginExtension]
    const inInternalCall = extensionStatus?.inInternalCall

    const allStatus = Object.keys(agent?.memberships ?? {})
      .filter((queueId) => userQueues.includes(queueId))
      .map((queueId) => agent.memberships[queueId].status)

    let status = membership?.status ?? agent.status

    if (!status && allStatus.includes(ONTHEPHONE) && talkingCallId) status = ONTHEPHONE
    if (!status && allStatus.includes(RINGING) && talkingCallId) status = RINGING
    if (!status && allStatus.includes(DIALING) && talkingCallId) status = DIALING
    if (!status && allStatus.includes(ONLINE)) status = ONLINE
    if (!status && allStatus.includes(PAUSED)) status = PAUSED
    if (!status && allStatus.includes(OFFLINE)) status = OFFLINE
    if (inInternalCall && agent.status === 'ONLINE') status = UNAVAILABLE

    callcenter.onlineAgentCalls += completedCount

    if (status === PAUSED) callcenter.paused++
    if (status === ONLINE) callcenter.online++
    if (status === DIALING) callcenter.dialing++
    if (status === RINGING) callcenter.ringing++
    if (status === ONTHEPHONE) callcenter.talking++

    let statusDesc = statusNames[status]
    if (talkingCall.queueId) statusDesc = state.queues[talkingCall.queueId] ? state.queues[talkingCall.queueId].name : talkingCall.queueId
    if (status === PAUSED) statusDesc = pauses[agent.pauseReasonId] || statusNames[status]

    let phoneNumber = talkingCall.callerNumber || talkingCall.dialedNumber || talkingCallId
    if (inInternalCall && allStatus.includes(ONLINE)) phoneNumber = inInternalCall

    const idleTime = getIdleTime(status, agent.loginAt, agent.lastCallAnsweredAt)
    const duration = getDurationTime(idleTime, status, agent, inInternalCall)
    return {
      id: agent.id,
      name: agent.name,
      extension: agent.loginExtension,

      penalty,
      status,
      statusDesc,
      extensionStatus,

      calls: agentCalls,
      phoneNumber: phoneNumber,
      phoneDirection: talkingCall.direction,

      duration,
      idleTime,

      rejections,
      completedCount,
      versionVP: state.versionVP
    }
  }).filter(activeAgents).sort(byCodeAsc)

  callcenter.calls = callcenter.inAbandoned + callcenter.outDiscarded + callcenter.inCompleted + callcenter.outCompleted + callcenter.autoCompleted
  callcenter.agents = agents.length

  return {
    callcenter,
    queueCalls,
    agents: agents.map((agent) => (
      Object.assign(agent, {
        completedPercent: agent.completedCount && callcenter.onlineAgentCalls ? parseFloat(agent.completedCount / callcenter.onlineAgentCalls).toFixed(4) : 0
      })
    ))
  }
}

const mapQueueAgent = (calls, queue, totalCalls, pauses, agent, stateExtensions, versionVP, showAgents) => {
  if (!activeAgents(agent)) return null

  let status = agent.memberships[queue]?.status ?? agent.status
  if (!status) status = agent.status

  if (!showAgents) {
    return {
      id: agent.id,
      name: agent.name,
      extension: agent.loginExtension,
      status
    }
  }

  const summary = Object.assign({}, agent.summaries[queue] || {})
  const rejections = summary.rejections || 0
  const completedCount = (summary.autoCompleted || 0) + (summary.inCompleted || 0) + (summary.outCompleted || 0)
  const completedPercent = completedCount && totalCalls ? parseFloat(completedCount / totalCalls).toFixed(4) : 0
  const talkingCallId = agent.talkingCallId && calls[agent.talkingCallId] && calls[agent.talkingCallId].queueId ? agent.talkingCallId : null
  const talkingCall = Object.assign({}, calls[talkingCallId] || {})
  const agentCalls = agent.calls.map((callId) => mapAgentCall(calls, callId)).filter(v => v)
  const extensionStatus = stateExtensions[agent.loginExtension]
  const inInternalCall = extensionStatus?.inInternalCall

  const penalty = agent.memberships[queue]?.penalty || 0

  if (talkingCallId && queue !== talkingCall.queueId) status = UNAVAILABLE
  if (talkingCallId && talkingCall.queueId === queue && talkingCall.status === 'CONNECTED') status = ONTHEPHONE
  if (inInternalCall && agent.status === 'ONLINE') status = UNAVAILABLE
  if (!talkingCallId && status === ONTHEPHONE && !agentCalls.length) status = ONLINE

  const idleTime = getIdleTime(status, agent.loginAt, agent.lastCallAnsweredAt)
  const duration = getDurationTime(idleTime, status, agent, inInternalCall)

  let statusDesc = statusNames[status] || status
  if (status === PAUSED) statusDesc = pauses[agent.pauseReasonId] || statusDesc

  let phoneNumber = talkingCall.callerNumber || talkingCall.dialedNumber || talkingCallId
  if (inInternalCall && agent.status === 'ONLINE') phoneNumber = inInternalCall

  return {
    id: agent.id,
    name: agent.name,
    extension: agent.loginExtension,

    penalty,
    calls: agentCalls,
    status,
    statusDesc,
    extensionStatus,
    versionVP,

    phoneNumber: phoneNumber,
    phoneDirection: talkingCall.direction,

    idleTime,
    duration,

    rejections,
    completedCount,
    completedPercent
  }
}

const activeAgents = ({ status }) => status && status !== OFFLINE
const byCodeAsc = ({ id: a }, { id: b }) => a > b ? 1 : (a < b ? -1 : 0)
const byCreatedAtDesc = ({ createdAt: a }, { createdAt: b }) => a > b ? 1 : (a < b ? -1 : 0)
const byCreatedAtAsc = ({ createdAt: a }, { createdAt: b }) => a < b ? 1 : (a > b ? -1 : 0)
const onlyHoldCalls = (calls, callId) => calls[callId] && calls[callId].status === ENQUEUED
const onlyOngoingCalls = (calls, callId) => calls[callId] && calls[callId].status === CONNECTED
const reduceHoldCall = (calls, callId) => {
  const { callerNumber, dialedNumber, queueJoinAt, createdAt, ...call } = calls[callId]
  const number = callerNumber || dialedNumber || callId
  return { id: callId, number, createdAt: Number(queueJoinAt) || createdAt, ...call }
}

const reduceOngoingCall = (calls, callId) => {
  const { createdAt, direction, callerNumber, dialedNumber, queueId, answerAt, agentId, status, callerInfo } = calls[callId]
  const number = callerNumber || dialedNumber || callId
  return { id: callId, queueId, number, direction, createdAt, answeredAt: answerAt, agentId, status, callerInfo }
}

export const formatSecs = (val) => {
  if (!val || isNaN(val)) return '-'

  const secs = Number(val)
  if (!secs) return '-'

  return formatElapsedSeconds(secs)
}

export const formatNum = (val, isResume = true) => {
  if (!val || isNaN(val)) return '0'

  const num = Number(val)
  if (!num) return '0'

  return numeral(num).format(isResume ? '0.[00] a' : '0,0.[00]')
}

export const formatDashNum = (val, isResume = true) => {
  if (!val || isNaN(val)) return '-'

  const num = Number(val)
  if (!num) return '-'

  return numeral(num).format(isResume ? '0.[00] a' : '0,0.[00]')
}

export const formatRound = (num, isResume = true) => {
  const val = isNaN(num) ? 0 : Number(num)
  if (!val) return '0'
  if (val < 1) return '1'
  return numeral(num).format(isResume ? '0 a' : '0,0')
}

export const getTimeZone = () => {
  return moment().format('Z')
}

export const formatPerc = (num) => {
  const val = isNaN(num) ? 0 : Number(num)
  if (!val) return '0%'
  if (val === 1) return '100%'
  return (val * 100).toFixed(2).replace('.', ',').concat('%')
}

const filterCalls = (calls, queueId) => {
  const list = []

  for (let i = calls.length; i > 0; i--) {
    if (calls[i - 1].queueId !== queueId) continue
    const [{ id, queueJoinAt, callerNumber, dialedNumber, direction }] = calls.splice(i - 1, 1)
    list.unshift({ id, number: callerNumber || dialedNumber || id, createdAt: Number(queueJoinAt), direction })
  }

  return list
}

const toQueueCall = (callcenter, activeCalls) => (queue) => {
  const id = queue.id
  const name = queue.name || queue.id
  const calls = filterCalls(activeCalls, queue.id)
  const summary = queue.summary || {}
  const weight = queue.weight

  callcenter.inCompleted += summary.inCompleted || 0
  callcenter.outCompleted += summary.outCompleted || 0
  callcenter.autoCompleted += summary.autoCompleted || 0
  callcenter.inCompletedSla += summary.inCompletedSla || 0

  callcenter.inAbandoned += summary.inAbandoned || 0
  callcenter.outDiscarded += summary.outDiscarded || 0

  callcenter.inCallSecs += summary.inCallSecs || 0
  callcenter.outCallSecs += summary.outCallSecs || 0
  callcenter.autoCallSecs += summary.autoCallSecs || 0

  callcenter.inHoldSecsAbandoned += summary.inHoldSecsAbandoned || 0
  callcenter.inHoldSecsCompleted += summary.inHoldSecsCompleted || 0

  return { id, name, calls, weight, ...checkSla(queue) }
}

const getPendingContacts = (queue) => {
  const getNumber = key => Number(queue?.[key] || 0)
  const keys = ['dialerCountFiltered', 'dialerCountCompleted', 'dialerCountFailure', 'dialerCountAbandons']

  const [filteredContacts, counCompleted, counFailure, counAbandons] = keys.map(getNumber)
  const totalDialed = counCompleted + counFailure + counAbandons

  return Math.max(0, filteredContacts - totalDialed)
}
